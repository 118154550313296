<script setup lang="ts">
import { defineProps } from "vue";
import TitleMedium from "@/components/Titles/TitleMedium.vue";
import TextMedium from "@/components/Texts/TextMedium.vue";

const props = defineProps<{
  title: string;
  text?: string;
}>();
</script>
<template>
  <div class="flex flex-row items-start w-full">
    <div class="w-2/12 h-full item">
      <slot />
    </div>
    <div class="w-10/12 md:w-5/6 flex flex-col space-y-2 ml-4">
      <TitleMedium :text="props.title" />
      <TextMedium :text="props.text ? props.text : 'None'" />
    </div>
  </div>
</template>
