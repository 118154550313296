<script setup lang="ts">
import { defineProps } from "vue";
import MapIcon from "@/components/Icons/MapIcon.vue";

const props = defineProps<{
  active: boolean;
  color: string;
  backgroundColor: string;
}>();
</script>

<template>
  <div
    class="relative p-2 m-2 flex justify-center items-center"
    :style="{ 'background-color': props.backgroundColor }"
  >
    <MapIcon :color="props.color" />
    <div v-if="props.active" class="absolute right-4 bottom-4">
      <div
        class="bg-custom-primaryBlue rounded-full p-1 flex items-center justify-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="white"
          class="bi bi-check2"
          viewBox="0 0 16 16"
        >
          <path
            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>
