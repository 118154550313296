<script setup lang="ts">
import { useMapStore } from "@/store/useMapStore";
import { computed, ref } from "vue";
import PaletteIcon from "@/components/Icons/PaletteIcon.vue";
import TextSmall from "@/components/Texts/TextSmall.vue";

const mapStore = useMapStore();
const legendColors = computed(() => {
  return mapStore.legendsColor;
});
const isOpen = ref(false);
const toggleLegend = () => {
  isOpen.value = !isOpen.value;
};
</script>

<template>
  <div class="relative z-10">
    <PaletteIcon @click="toggleLegend" />
  </div>
  <div
    v-if="isOpen"
    class="absolute w-44 min-h-64 h-auto bg-custom-primaryBlack bottom-0 right-0 rounded-br-3xl px-2 py-2 border border-white"
  >
    <div
      v-for="legendColor in legendColors"
      :key="legendColor.name"
      class="flex flex-row space-x-2 space-y-2 items-center"
    >
      <div
        class="w-6 h-6 bg-red-500 border border-custom-lightGray"
        :style="'background-color: ' + legendColor.color"
      ></div>
      <TextSmall :text="legendColor.name" />
    </div>
  </div>
</template>
