<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useFriendsStore } from "@/store/useFriendsStore";
import { useAuthStore } from "@/store/useAuthStore";
import UserManageView from "@/components/Friends/UserManageView.vue";
import TitleMedium from "@/components/Titles/TitleMedium.vue";
import InputCheckbox from "@/components/Form/InputCheckbox.vue";

const friendsStore = useFriendsStore();
const authStore = useAuthStore();

const friendsInfos = computed(() => {
  return friendsStore.friendsInfos;
});

const currentUser = computed(() => {
  return authStore.currentUser;
});

const common = ref(authStore.currentUser.common);

watch(
  () => common.value,
  () => {
    authStore.updateCommon(common.value);
  },
  { deep: true }
);
</script>

<template>
  <div class="flex flex-col space-y-4">
    <TitleMedium text="Tailor your map view" />
    <UserManageView :friend_info="{ friendId: currentUser.id }" />
    <hr />
    <div v-if="friendsInfos.length > 0" class="flex flex-col w-full space-y-4">
      <UserManageView
        v-for="friend_info in friendsInfos"
        :key="friend_info.friendId"
        :friend_info="friend_info"
      />
    </div>
    <InputCheckbox
      label="See only common countries"
      id="common"
      error="common"
      v-model="common"
      name="common"
    />
  </div>
</template>
