<script setup lang="ts">
import { defineProps, defineEmits, computed } from "vue";
import LabelBase from "@/components/Texts/LabelBase.vue";

const props = defineProps<{
  name: string;
  label?: string;
  id: string;
  is_required?: boolean;
  modelValue: boolean;
  error?: string;
}>();

defineEmits(["update:modelValue"]);
</script>

<template>
  <div class="flex flex-row space-x-2">
    <div class="flex items-center">
      <input
        :name="props.name"
        :id="props.id"
        :required="props.is_required"
        :checked="props.modelValue"
        :type="'checkbox'"
        @change="
          $emit(
            'update:modelValue',
            ($event.target as HTMLInputElement).checked
          )
        "
        class="form-checkbox h-5 w-5 text-custom-primaryBlack"
      />
    </div>
    <LabelBase
      v-if="props.label"
      :for="props.id"
      :text="props.label"
      class="text-white"
    />
  </div>
</template>
