<script setup lang="ts">
import { computed, defineProps, Ref } from "vue";
import { StatusCountry } from "@/interfaces/StatusCountry";
import { useMapThemeStore } from "@/store/useMapThemeStore";
import { Theme } from "@/interfaces/Theme";
import { useAuthStore } from "@/store/useAuthStore";
import { useMapStore } from "@/store/useMapStore";
import UnvisitedIcon from "@/components/Icons/UnvisitedIcon.vue";
import VisitedIcon from "@/components/Icons/VisitedIcon.vue";
import ToVisitIcon from "@/components/Icons/ToVisitIcon.vue";

const props = defineProps<{
  status: StatusCountry;
  countryCode: string;
  withText: boolean;
}>();

const authStore = useAuthStore();
const mapThemeStore = useMapThemeStore();

const theme: Ref<Theme> = computed(() => {
  if (authStore.currentUser) {
    return mapThemeStore.getThemeByName(authStore.currentUser.selectedTheme);
  } else {
    return mapThemeStore.themes[0];
  }
});

const themeStyle = computed(() => {
  switch (props.status) {
    case StatusCountry.Visited:
      return { backgroundColor: theme.value?.visited };
    case StatusCountry.ToVisit:
      return { backgroundColor: theme.value?.toVisit };
    case StatusCountry.Unvisited:
      return { backgroundColor: theme.value?.unvisited };
    default:
      return { backgroundColor: theme.value?.unvisited };
  }
});

const countryUser = computed(() => {
  return authStore.getCountryById(props.countryCode);
});

const active = computed(() => {
  return countryUser.value.status === props.status;
});

const mapStore = useMapStore();

const updateStatus = (status: StatusCountry) => {
  mapStore.updateCountryStatusByCode(props.countryCode, status);
};
</script>

<template>
  <div
    class="py-2"
    :class="{
      'bg-custom-lightGray text-custom-darkGray': !active,
      'text-white': active,
    }"
    :style="active ? themeStyle : ''"
    @click="updateStatus(props.status)"
    @touch="updateStatus(props.status)"
  >
    <div
      v-if="props.status === StatusCountry.Unvisited"
      class="flex flex-col space-y-2"
    >
      <UnvisitedIcon class="w-3/12 md:w-2/12 mx-auto" />
      <p v-if="props.withText" class="text-center">Unvisited</p>
    </div>
    <div
      v-if="props.status === StatusCountry.Visited"
      class="flex flex-col space-y-2"
    >
      <VisitedIcon class="w-3/12 md:w-2/12 mx-auto" />
      <p v-if="props.withText" class="text-center">Visited</p>
    </div>
    <div
      v-if="props.status === StatusCountry.ToVisit"
      class="flex flex-col space-y-2"
    >
      <ToVisitIcon class="w-3/12 md:w-2/12 mx-auto" />
      <p v-if="props.withText" class="text-center">To Visit</p>
    </div>
  </div>
</template>
