<script setup lang="ts">
import InputText from "@/components/Form/InputText.vue";
import { ref } from "vue";
import ButtonBase from "@/components/Buttons/ButtonPrimary.vue";
import { useAuthStore } from "@/store/useAuthStore";
import TitleBig from "@/components/Titles/TitleBig.vue";
import InputColor from "@/components/Form/InputColor.vue";
import RouterLinkCustom from "@/components/Texts/RouterLinkCustom.vue";
import LabelBase from "@/components/Texts/LabelBase.vue";
const authStore = useAuthStore();

const nameValue = ref("");
const emailValue = ref("");
const passwordValue = ref("");
const repeatPasswordValue = ref("");
const colorValue = ref("#8b5df2");

const tryRegister = () => {
  authStore.registerUser(
    nameValue.value,
    emailValue.value,
    colorValue.value,
    passwordValue.value,
    repeatPasswordValue.value
  );
};
</script>

<template>
  <div
    class="container mx-auto px-8 flex flex-row md:items-center md:justify-center h-screen"
  >
    <div class="w-full md:w-2/3 mx-auto flex flex-col space-y-8 mt-8 md:mt-0">
      <TitleBig text="Register" />
      <div
        class="grid grid-cols-1 md:grid-cols-3 md:gap-x-8 gap-y-4 md:gap-y-8"
      >
        <InputText
          name="name"
          :is_required="true"
          id="name"
          v-model="nameValue"
          placeholder="Name"
          type="text"
          error="name"
        />
        <InputText
          name="email"
          :is_required="true"
          id="email"
          v-model="emailValue"
          placeholder="Email"
          type="email"
          error="email"
        />
        <div class="flex flex-col space-y-4">
          <LabelBase text="Color" />
          <InputColor
            name="color"
            placeholder="Color"
            id="color"
            v-model="colorValue"
            :is_required="true"
          />
        </div>
        <InputText
          name="password"
          :is_required="true"
          id="password"
          v-model="passwordValue"
          placeholder="Password"
          type="password"
          error="password"
        />
        <InputText
          name="repeatPassword"
          :is_required="true"
          id="repeat-password"
          v-model="repeatPasswordValue"
          placeholder="Repeat password"
          type="password"
          error="password"
        />
      </div>
      <ButtonBase
        class="w-1/2 md:w-1/4 m-auto md:m-0"
        text="Sign up"
        @click="tryRegister"
      />
      <RouterLinkCustom to="/login" text="Already have an account ?" />
    </div>
  </div>
</template>
