<script setup lang="ts">
import { defineEmits, defineProps } from "vue";

const props = defineProps<{
  text: string;
}>();
</script>

<template>
  <button
    class="border-2 border-custom-purple text-custom-purple rounded-md py-2"
  >
    {{ props.text }}
  </button>
</template>
