import { defineStore } from "pinia";
import { ref, Ref } from "vue";
import countriesData from "../json/countries.json";
import onuCountriesData from "../json/countriesONU.json";
import { CountryWithStatus } from "@/interfaces/CountryWithStatus";
import { StatusCountry } from "@/interfaces/StatusCountry";
import { Country } from "@/interfaces/Country";

// https://gist.github.com/keeguon/2310008
// https://github.com/samayo/country-json/blob/master/src/country-by-abbreviation.json

// country flag emoji json :
// https://github.com/risan/country-flag-emoji-json

// liste json countries
// https://gist.github.com/keeguon/2310008
// https://www.npmjs.com/package/planetpulse
// https://www.npmjs.com/package/country-json

export const useCountriesStore = defineStore("countries", () => {
  const countries: Ref<Country[]> = ref(transformCountryData(countriesData));
  const onuCountries: string[] = onuCountriesData;

  function getCountryById(code: string) {
    return countries.value.find(
      (countryWithStatus) => countryWithStatus.code === code
    );
  }

  function transformCountryData(data: any[]): Country[] {
    return data.map((item) => ({
      ...item,
      currency: {
        ...item.currency,
        symbol: item.currency.symbol || null,
      },
    }));
  }

  function generateCountryStatusesBase(): CountryWithStatus[] {
    return countries.value.map((countryWithStatus) => ({
      countryCode: countryWithStatus.code,
      status: StatusCountry.Unvisited,
    }));
  }

  return {
    countries,
    onuCountries,
    getCountryById,
    generateCountryStatusesBase,
  };
});
