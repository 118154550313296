<script setup lang="ts">
import AvatarWithColor from "@/components/Friends/AvatarWithColor.vue";
import TextMedium from "@/components/Texts/TextMedium.vue";
import { useUsersStore } from "@/store/useUsersStore";
import { defineProps, defineEmits, watch } from "vue";
import ThreeDotsIcon from "@/components/Icons/ThreeDotsIcon.vue";
import InputCheckbox from "@/components/Form/InputCheckbox.vue";
import { computed, ComputedRef, ref } from "vue";
import { useAuthStore } from "@/store/useAuthStore";
import { FriendInfos } from "@/interfaces/FriendInfos";
import ButtonSecondary from "@/components/Buttons/ButtonSecondary.vue";
import InputColor from "@/components/Form/InputColor.vue";
import { useFriendsStore } from "@/store/useFriendsStore";
import { useDialogsStore } from "@/store/useDialogsStore";
import BottomSheet from "@/components/Menus/BottomSheet.vue";
import TitleMedium from "@/components/Titles/TitleMedium.vue";
import ButtonPrimary from "@/components/Buttons/ButtonPrimary.vue";
const authStore = useAuthStore();
const friendsStore = useFriendsStore();

const props = defineProps<{
  friend_info: FriendInfos;
}>();

const { getUserById } = useUsersStore();
const user = computed(() => {
  return getUserById(props.friend_info.friendId);
});

const color: ComputedRef<string> = computed(() => {
  if (props.friend_info.friendId === authStore.currentUser?.id) {
    return authStore.currentUser.color;
  } else {
    if (props.friend_info.friendRequestInfos) {
      return props.friend_info.friendRequestInfos.from ===
        props.friend_info.friendId
        ? props.friend_info.friendRequestInfos.colorFrom
        : props.friend_info.friendRequestInfos.colorTo;
    }
  }
  return "#ffffff";
});

const selectedUsers = computed(() => {
  return authStore.currentUser?.selectedUsers || [];
});

const emit = defineEmits(["update:selectedUsers"]);

const updateSelectedUsers = (event: Event) => {
  const selectedUsersNew = authStore.currentUser?.selectedUsers;
  if (event.target instanceof HTMLInputElement && selectedUsersNew) {
    if (user.value) {
      if (event.target.checked) {
        selectedUsersNew.push(user.value.id);
      } else {
        const index = selectedUsersNew.indexOf(user.value.id);
        if (index !== -1) {
          selectedUsersNew.splice(index, 1);
        }
      }
    }
    authStore.updateSelectedUsers(selectedUsersNew);
  }
};

const colorCustom = ref(color.value);

watch(
  () => colorCustom.value,
  () => {
    if (props.friend_info.friendRequestInfos) {
      friendsStore.updateColor(
        colorCustom.value,
        props.friend_info.friendRequestInfos.from ===
          props.friend_info.friendId,
        props.friend_info.friendRequestInfos.from,
        props.friend_info.friendRequestInfos.to
      );
    } else {
      authStore.updateColor(colorCustom.value);
    }
  },
  { deep: true }
);

const dialogsStore = useDialogsStore();

const friendCustomDialog = dialogsStore.createNewDialog();
const friendDeleteDialog = dialogsStore.createNewDialog();

const toggleFriendDeleteDialog = () => {
  if (dialogsStore.dialogs[friendDeleteDialog]) {
    dialogsStore.closeOnlyOneModal(friendDeleteDialog);
  } else {
    dialogsStore.openModalNoClosing(friendDeleteDialog);
  }
};

const toggleFriendCustomDialog = () => {
  if (dialogsStore.dialogs[friendCustomDialog]) {
    dialogsStore.closeOnlyOneModal(friendCustomDialog);
  } else {
    dialogsStore.openModalNoClosing(friendCustomDialog);
  }
};

const isOpen = computed(() => {
  return dialogsStore.dialogs[friendCustomDialog];
});

const deleteFriend = () => {
  if (props.friend_info.friendRequestInfos) {
    // delete friend
    friendsStore.deleteFriendRequest(
      props.friend_info.friendRequestInfos.from,
      props.friend_info.friendRequestInfos.to
    );
    // update selected users
    const selectedUsersNew = authStore.currentUser?.selectedUsers;
    const index = selectedUsersNew.indexOf(props.friend_info.friendId);
    if (index !== -1) {
      selectedUsersNew.splice(index, 1);
    }
    authStore.updateSelectedUsers(selectedUsersNew);
    // close modal
    dialogsStore.closeOnlyOneModal(friendDeleteDialog);
  }
};
</script>

<template>
  <div class="flex flex-col space-y-4 mr-4">
    <div class="flex flex-row space-x-4 items-end">
      <AvatarWithColor :color="color" class="flex-none h-12 mb-1" />
      <InputCheckbox
        :name="user.id.toString()"
        :modelValue="selectedUsers.includes(user.id)"
        @change="updateSelectedUsers"
        :id="user.id.toString()"
        class="w-1/12 mb-1"
      />
      <TextMedium class="flex-grow ml-2 md:ml-0" :text="user.name" />
      <ThreeDotsIcon class="text-white h-6" @click="toggleFriendCustomDialog" />
    </div>
    <div v-if="isOpen" class="flex flex-row space-x-4">
      <div class="flex-grow md:w-9/12">
        <InputColor
          name="color"
          placeholder="color"
          id="color"
          v-model="colorCustom"
          label="Change color"
        />
      </div>
      <div
        v-if="props.friend_info.friendRequestInfos"
        class="md:w-3/12 ml-auto"
      >
        <ButtonSecondary
          text="Delete"
          class="w-full"
          @click="toggleFriendDeleteDialog"
        />
      </div>
      <div v-else class="md:w-3/12 h-0" />
    </div>
  </div>

  <div v-if="props.friend_info.friendRequestInfos">
    <BottomSheet
      :dialog-id="friendDeleteDialog"
      :out-side-closable="true"
      :all-draggable="false"
    >
      <div class="flex flex-col space-y-4">
        <TitleMedium text="Delete friend" />
        <TextMedium text="Are you sure you want to delete this friend?" />
        <div class="flex flex-row space-x-4">
          <ButtonSecondary
            text="Cancel"
            class="w-1/2"
            @click="dialogsStore.closeOnlyOneModal(friendDeleteDialog)"
          />
          <ButtonPrimary text="Delete" class="w-1/2" @click="deleteFriend()" />
        </div>
      </div>
    </BottomSheet>
  </div>
</template>
