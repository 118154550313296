<script setup lang="ts">
import { defineProps } from "vue/dist/vue";

const props = defineProps<{
  color: string;
}>();
</script>

<template>
  <svg
    viewBox="0 0 128 128"
    :fill="props.color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_i_71_5990)">
      <path
        d="M128 30.2436V97.7124C128.004 101.688 127.224 105.626 125.705 109.301C124.186 112.975 121.958 116.314 119.148 119.127C116.338 121.94 113.001 124.171 109.328 125.694C105.655 127.216 101.718 128 97.7417 128H30.273C22.2478 127.996 14.5522 124.807 8.87623 119.134C3.20021 113.461 0.00778584 105.767 1.42309e-05 97.7417V30.2729C-0.00384206 26.2976 0.776073 22.3605 2.29515 18.6868C3.81422 15.0132 6.04265 11.675 8.85297 8.86333C11.6633 6.05165 15.0003 3.82161 18.6733 2.30075C22.3462 0.779896 26.2829 -0.00192622 30.2583 3.56376e-06H97.7271C101.701 -0.00192302 105.636 0.778823 109.308 2.29766C112.979 3.81651 116.316 6.0437 119.127 8.85208C121.938 11.6605 124.169 14.995 125.691 18.6654C127.214 22.3358 127.998 26.27 128 30.2436Z"
        :fill="props.color"
      />
    </g>
    <path
      d="M82.7 53C82.5289 52.9982 82.3592 52.9685 82.1973 52.9121C81.9839 52.8453 81.7855 52.7361 81.6136 52.5908C81.4416 52.4455 81.2996 52.267 81.1955 52.0655C81.0914 51.864 81.0273 51.6435 81.007 51.4167C80.9867 51.1899 81.0105 50.9612 81.0771 50.7438C81.8905 47.9013 83.5951 45.4093 85.9296 43.6496C88.2642 41.8899 91.1 40.9596 94.0024 41.0013C96.9015 40.9742 99.7305 41.91 102.062 43.6676C104.394 45.4251 106.102 47.9089 106.928 50.7438C107.058 51.1848 107.011 51.6604 106.799 52.0666C106.586 52.4729 106.225 52.7768 105.793 52.9121C105.58 52.9821 105.355 53.0079 105.133 52.9881C104.91 52.9684 104.693 52.9033 104.495 52.7969C104.297 52.6904 104.122 52.5447 103.98 52.3683C103.838 52.1918 103.731 51.9882 103.668 51.7694C103.045 49.638 101.76 47.771 100.006 46.4497C98.2523 45.1285 96.125 44.4246 93.945 44.4442C91.7747 44.4373 89.6605 45.1471 87.9184 46.4674C86.1763 47.7877 84.8998 49.6477 84.2797 51.7694C84.1732 52.1158 83.9642 52.4201 83.6813 52.6404C83.3984 52.8608 83.0556 52.9864 82.7 53Z"
      fill="black"
    />
    <path
      d="M21.7126 52.9999C21.5413 53.0015 21.3711 52.9717 21.2101 52.912C20.9954 52.8467 20.7956 52.7386 20.6222 52.594C20.4487 52.4494 20.3051 52.2711 20.1996 52.0694C20.0941 51.8678 20.0289 51.6468 20.0076 51.4192C19.9864 51.1917 20.0096 50.9621 20.0759 50.7437C20.8932 47.9037 22.5984 45.4144 24.9314 43.6554C27.2643 41.8964 30.097 40.9642 32.9975 41.0011C35.8969 40.9691 38.7273 41.9031 41.0593 43.6614C43.3913 45.4197 45.0976 47.9062 45.9192 50.7437C45.9877 50.9611 46.0131 51.1902 45.9937 51.4176C45.9743 51.6451 45.9106 51.8663 45.8063 52.0683C45.7019 52.2703 45.5591 52.449 45.3862 52.5939C45.2133 52.7387 45.0137 52.8469 44.7993 52.912C44.3671 53.0497 43.899 53.0066 43.4979 52.7923C43.0968 52.5781 42.7955 52.2101 42.66 51.7693C42.0381 49.6403 40.7555 47.775 39.0051 46.454C37.2548 45.1329 35.1315 44.4275 32.9544 44.444C30.7823 44.4339 28.6655 45.1422 26.921 46.4628C25.1764 47.7834 23.8982 49.6452 23.2776 51.7693C23.1738 52.1146 22.9674 52.4184 22.6871 52.6389C22.4068 52.8593 22.0663 52.9855 21.7126 52.9999Z"
      fill="black"
    />
    <path
      d="M64.5423 92H63.789C54.0857 91.782 42.994 86.7096 40.0845 75.8527C40.0178 75.6722 39.9901 75.48 40.0031 75.2884C40.0162 75.0968 40.0697 74.9099 40.1603 74.7398C40.2509 74.5697 40.3766 74.4201 40.5293 74.3005C40.6819 74.1809 40.8583 74.0939 41.0471 74.0452C41.2359 73.9965 41.4329 73.987 41.6256 74.0175C41.8183 74.0479 42.0024 74.1176 42.1662 74.2221C42.3299 74.3265 42.4698 74.4635 42.5767 74.6241C42.6837 74.7848 42.7554 74.9656 42.7873 75.1551C45.3423 84.6167 55.2081 89.0496 63.8481 89.2385C72.0893 89.3984 82.8856 85.3977 86.312 74.6716C86.4917 74.4182 86.7488 74.2278 87.046 74.128C87.3431 74.0281 87.6649 74.0241 87.9645 74.1165C88.2641 74.2088 88.5261 74.3927 88.7123 74.6415C88.8985 74.8903 88.9993 75.191 89 75.5C85.8542 85.3105 76.2985 92 64.5423 92Z"
      fill="black"
    />
    <defs>
      <filter
        id="filter0_i_71_5990"
        x="0"
        y="0"
        width="128"
        height="128"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-6" dy="-6" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_71_5990"
        />
      </filter>
    </defs>
  </svg>
</template>
