<script setup lang="ts">
import { defineProps } from "vue/dist/vue";

const props = defineProps<{
  text: string;
  route: string;
}>();
</script>

<template>
  <router-link
    class="text-custom-middleGray text-left text-lg md:text-xl"
    :to="props.route"
  >
    {{ props.text }}
  </router-link>
</template>

<style lang="scss">
a {
  color: #ffffff;

  &.router-link-exact-active {
    color: #8b5df2;
  }
}
</style>
