<script setup lang="ts">
import { computed, ref } from "vue";
import { useFriendsStore } from "@/store/useFriendsStore";
import { useAuthStore } from "@/store/useAuthStore";
import TitleMedium from "@/components/Titles/TitleMedium.vue";
import InputText from "@/components/Form/InputText.vue";
import ButtonPrimary from "@/components/Buttons/ButtonPrimary.vue";
import TextSmall from "@/components/Texts/TextSmall.vue";
import FriendRequestManageView from "@/components/Friends/FriendRequestManageView.vue";
import FriendNotificationManageView from "@/components/Friends/FriendNotificationManageView.vue";

const friendsStore = useFriendsStore();
const authStore = useAuthStore();

const nameValue = ref("");

const error = ref(false);
const success = ref(false);

const addFriend = () => {
  error.value = false;
  success.value = false;
  const isSuccess = friendsStore.addFriendRequest(
    authStore.currentUser.id,
    nameValue.value
  );
  if (isSuccess) {
    success.value = true;
  } else {
    error.value = true;
  }
};

const friendRequestsToMe = computed(() => {
  return friendsStore.friendRequestsToMe;
});
</script>

<template>
  <div class="flex flex-col space-y-4">
    <TitleMedium text="Notifications" />
    <div
      v-if="friendRequestsToMe.length > 0"
      class="flex flex-col w-full space-y-4"
    >
      <FriendNotificationManageView
        v-for="friend in friendRequestsToMe"
        :key="friend.from"
        :friends-request="friend"
      />
    </div>
  </div>
</template>
