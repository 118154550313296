import { createApp, Directive } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import "./index.css";
import { useAuthStore } from "@/store/useAuthStore";
import "./registerServiceWorker";
import { vue3Debounce } from "vue-debounce";

const pinia = createPinia();

const app = createApp(App)
  .use(router)
  .use(pinia)
  .directive(
    "debounce",
    vue3Debounce({ lock: true, listenTo: "input" }) as Directive
  );

router.isReady().then(() => {
  app.mount("#app");
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  if (to.meta.requiresAuth && !authStore.currentUser) {
    next("/login");
  } else if (!to.meta.requiresAuth && authStore.currentUser) {
    next("/world-map");
  } else {
    next();
  }
});
