<script setup lang="ts">
import { useDialogsStore } from "@/store/useDialogsStore";
import { computed, ref, defineProps } from "vue";

const dialogsStore = useDialogsStore();

const props = defineProps<{
  dialogId: number;
  outSideClosable?: boolean;
  allDraggable: boolean;
}>();

const isOpen = computed<boolean>(() => {
  return dialogsStore.dialogs[props.dialogId];
});

const isDragging = ref(false);
const initialMouseY = ref(0);
const offsetY = ref(0);
const modalRef = ref<HTMLElement | null>(null);

const startDrag = (e: MouseEvent | TouchEvent) => {
  const clientY =
    "touches" in e ? e.touches[0].clientY : (e as MouseEvent).clientY;
  if (isInDraggableArea(e.target)) {
    e.preventDefault();
    isDragging.value = true;
    initialMouseY.value = clientY;
  }
};

const onDrag = (e: MouseEvent | TouchEvent) => {
  if (isDragging.value) {
    const clientY =
      "touches" in e ? e.touches[0].clientY : (e as MouseEvent).clientY;
    e.preventDefault();
    const delta = clientY - initialMouseY.value;

    if (delta > 0) {
      offsetY.value = delta;
    }
  }
};

const stopDrag = () => {
  if (isDragging.value) {
    isDragging.value = false;
    if (offsetY.value > 200) {
      dialogsStore.closeAllModals();
      offsetY.value = 0;
    } else {
      offsetY.value = 0;
    }
  }
};

const isInDraggableArea = (target: EventTarget | null): boolean | null => {
  return modalRef.value && modalRef.value.contains(target as Node);
};
</script>

<template>
  <Transition>
    <div v-if="isOpen" class="relative z-[60]">
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity bottom-0"
        @click="dialogsStore.closeAllModals()"
      ></div>
      <div
        v-if="!props.allDraggable"
        :style="{ transform: `translateY(${offsetY}px)` }"
        ref="modalRef"
        class="bg-custom-primaryBlack transition-opacity fixed inset-x-0 bottom-0 w-full h-4/5 md:w-2/5 m-auto rounded-t-2xl flex flex-col items-center"
      >
        <div
          id="modal"
          class="w-full"
          :class="{ draggable: !isDragging, dragging: isDragging }"
          @mousedown="startDrag"
          @mousemove="onDrag"
          @mouseup="stopDrag"
          @touchstart="startDrag"
          @touchmove="onDrag"
          @touchend="stopDrag"
        >
          <div class="w-1/6 h-1 bg-gray-400 rounded-2xl mt-4 mx-auto"></div>
        </div>
        <div class="p-4 h-full w-full overflow-y-auto">
          <slot />
        </div>
      </div>
      <div
        v-else
        id="modal"
        ref="modalRef"
        :style="{ transform: `translateY(${offsetY}px)` }"
        class="bg-custom-primaryBlack transition-opacity fixed inset-x-0 bottom-0 w-full h-4/5 md:w-2/5 m-auto rounded-t-2xl flex flex-col items-center"
        :class="{ draggable: !isDragging, dragging: isDragging }"
        @mousedown="startDrag"
        @mousemove="onDrag"
        @mouseup="stopDrag"
        @touchstart="startDrag"
        @touchmove="onDrag"
        @touchend="stopDrag"
      >
        <div class="w-1/6 h-1 bg-gray-400 rounded-2xl mt-4 mb-4"></div>
        <div class="p-4 h-full w-full overflow-y-auto">
          <slot />
        </div>
      </div>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.draggable {
  cursor: grab;
}

.dragging {
  cursor: grabbing;
}
</style>
