<script setup lang="ts">
import { defineProps } from "vue";

const props = defineProps<{
  text1: string;
  text2: string;
}>();
</script>

<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="35" cy="35" r="35" fill="url(#paint0_linear_106_2323)" />
    <circle cx="35" cy="35" r="31" fill="#262626" />
    <text
      x="50%"
      y="45%"
      text-anchor="middle"
      alignment-baseline="middle"
      fill="white"
      font-size="15"
    >
      {{ props.text1 }}
    </text>
    <text
      x="50%"
      y="65%"
      text-anchor="middle"
      alignment-baseline="middle"
      fill="white"
      font-size="9"
    >
      {{ props.text2 }}
    </text>
    <defs>
      <linearGradient
        id="paint0_linear_106_2323"
        x1="35"
        y1="0"
        x2="35"
        y2="70"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#60A5FA" />
        <stop offset="1" stop-color="#8B5DF1" />
      </linearGradient>
    </defs>
  </svg>
</template>
