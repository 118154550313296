<script setup lang="ts">
import { computed, defineProps } from "vue";
import { useDialogsStore } from "@/store/useDialogsStore";
import ChevronDownIcon from "@/components/Icons/ChevronDownIcon.vue";
import ChevronUpIcon from "@/components/Icons/ChevronUpIcon.vue";
import TextMedium from "@/components/Texts/TextMedium.vue";
import { useCountriesStore } from "@/store/useCountriesStore";
import CountryList from "@/components/Menus/CountryList.vue";

const props = defineProps<{
  name: string;
  dialogId: number;
  search?: string;
}>();

const countriesStore = useCountriesStore();
const dialogsStore = useDialogsStore();

const isOpen = computed<boolean>(() => {
  return dialogsStore.dialogs[props.dialogId];
});

const toggleContinentMenu = () => {
  if (dialogsStore.dialogs[props.dialogId]) {
    dialogsStore.closeOnlyOneModal(props.dialogId);
  } else {
    dialogsStore.openModalNoClosing(props.dialogId);
  }
};

const countries = computed(() => {
  let countriesList = [];
  for (const countryOnu of countriesStore.onuCountries) {
    const countryInfos = countriesStore.countries.find(
      (countriesStore) => countriesStore.code === countryOnu
    );
    if (
      countryInfos?.continent === props.name &&
      (!props.search ||
        countryInfos.name.toLowerCase().includes(props.search.toLowerCase()))
    ) {
      countriesList.push(countryInfos);
    }
  }
  return countriesList;
});
</script>

<template>
  <div class="flex flex-col space-y-4">
    <div
      class="flex flex-row max-h-100 overflow-y-auto"
      @click="toggleContinentMenu"
      @touch="toggleContinentMenu"
    >
      <TextMedium
        class="mr-auto"
        :text="props.name + '(' + countries.length + ')'"
      />
      <ChevronUpIcon v-if="isOpen" class="ml-auto text-white mt-1" />
      <ChevronDownIcon v-else class="ml-auto text-white mt-1" />
    </div>
    <div v-if="isOpen">
      <CountryList
        v-for="country in countries"
        :key="country.name"
        :country-infos="country"
      />
    </div>
  </div>
</template>
