<script setup lang="ts">
import { computed, defineEmits, defineProps, Ref, ref } from "vue";
import LabelBase from "@/components/Texts/LabelBase.vue";
import TextError from "@/components/Texts/TextError.vue";
import { useErrorsStore } from "@/store/useErrorsStore";
const errorsStore = useErrorsStore();

const props = defineProps<{
  name: string;
  placeholder: string;
  id: string;
  is_required: boolean;
  modelValue: string;
  type: string;
  error?: string;
}>();

const errorMessage = computed(() => {
  if (props.error) {
    const errorInput = errorsStore.getErrorByName(props.error);
    if (errorInput) {
      return errorInput.message;
    }
  }
  return "";
});

defineEmits(["update:modelValue"]);
</script>

<template>
  <div class="flex flex-col space-y-2">
    <LabelBase :text="props.placeholder" />
    <div class="p-1 rounded-full custom-bg">
      <input
        :name="props.name"
        :placeholder="props.placeholder"
        :id="props.id"
        :required="props.is_required"
        :value="modelValue"
        :type="props.type"
        @input="
          $emit('update:modelValue', ($event.target as HTMLInputElement).value)
        "
        class="bg-custom-primaryBlack text-white rounded-full px-5 py-3 w-full"
      />
    </div>
    <TextError v-if="props.error && errorMessage" :text="errorMessage" />
  </div>
</template>

<style scoped>
.custom-bg {
  background-image: linear-gradient(to top, #8b5df2, #60a5fa);
}
</style>
