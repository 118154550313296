<script setup lang="ts">
import { defineProps } from "vue/dist/vue";
import TitleBase from "@/components/Titles/TitleBase.vue";

const props = defineProps<{
  text: string;
}>();
</script>

<template>
  <TitleBase class="text-xl md:text-2xl" :text="props.text" />
</template>
