<script setup lang="ts">
import { defineProps } from "vue/dist/vue";
import TextBase from "@/components/Texts/TextBase.vue";

const props = defineProps<{
  text: string;
}>();
</script>

<template>
  <TextBase class="text-sm" :text="props.text" />
</template>
