<script setup lang="ts"></script>

<template>
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="26" cy="26" r="26" fill="url(#paint0_linear_262_2516)" />
    <ellipse cx="25.9998" cy="26" rx="23.0286" ry="23.0286" fill="#262626" />
    <path
      d="M33.0179 30.3915C34.8335 27.9141 35.6467 24.8424 35.2948 21.7912C34.9429 18.7399 33.4518 15.934 31.12 13.9349C28.7881 11.9358 25.7875 10.8908 22.7183 11.009C19.649 11.1273 16.7377 12.4001 14.5666 14.5727C12.3955 16.7454 11.1248 19.6577 11.0087 22.727C10.8926 25.7963 11.9398 28.7962 13.9406 31.1266C15.9414 33.457 18.7483 34.946 21.7998 35.2957C24.8514 35.6455 27.9224 34.8301 30.3986 33.0128H30.3967C30.4529 33.0878 30.5129 33.159 30.5804 33.2284L37.7992 40.4471C38.1508 40.799 38.6277 40.9967 39.1251 40.9969C39.6225 40.9971 40.0995 40.7997 40.4514 40.4481C40.8032 40.0965 41.001 39.6196 41.0011 39.1222C41.0013 38.6248 40.8039 38.1477 40.4523 37.7959L33.2336 30.5772C33.1665 30.5093 33.0944 30.4485 33.0179 30.3915ZM33.5017 23.184C33.5017 24.5383 33.2349 25.8793 32.7167 27.1304C32.1984 28.3816 31.4388 29.5185 30.4812 30.4761C29.5236 31.4337 28.3868 32.1933 27.1356 32.7115C25.8844 33.2298 24.5434 33.4965 23.1892 33.4965C21.8349 33.4965 20.4939 33.2298 19.2428 32.7115C17.9916 32.1933 16.8547 31.4337 15.8971 30.4761C14.9395 29.5185 14.1799 28.3816 13.6617 27.1304C13.1434 25.8793 12.8767 24.5383 12.8767 23.184C12.8767 20.449 13.9632 17.826 15.8971 15.892C17.8311 13.958 20.4541 12.8715 23.1892 12.8715C25.9242 12.8715 28.5473 13.958 30.4812 15.892C32.4152 17.826 33.5017 20.449 33.5017 23.184Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_262_2516"
        x1="26"
        y1="0"
        x2="26"
        y2="52"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#60A5FA" />
        <stop offset="1" stop-color="#8B5DF1" />
      </linearGradient>
    </defs>
  </svg>
</template>
