<script setup lang="ts">
import { defineProps } from "vue/dist/vue";

const props = defineProps<{
  text: string;
}>();
</script>

<template>
  <p class="text-white text-left">{{ props.text }}</p>
</template>
