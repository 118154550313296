<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    fill="currentColor"
    viewBox="0 0 100 125"
    xml:space="preserve"
  >
    <path
      d="M66.808,53.895v0.003c-0.543,0.003-1.086,0.025-1.627,0.069c9.155,0.741,16.207,8.327,16.215,17.441  c0,9.673-7.902,17.514-17.65,17.514c-9.154-0.006-16.785-6.954-17.574-16.005c-0.045,0.51-0.071,1.019-0.078,1.529  C46.093,85.797,55.366,95,66.806,95c0,0,0.001,0,0.002,0c11.439,0,20.714-9.201,20.714-20.552c0,0,0,0,0-0.002  C87.521,63.096,78.247,53.895,66.808,53.895z"
    />
    <polygon
      points="35.13,56.271 23.643,60.528 19.946,50.553 14.203,52.682 17.9,62.657 6.866,66.746 8.938,72.338 19.973,68.249   29.552,94.096 35.296,91.967 25.715,66.12 37.203,61.862 "
    />
    <path
      d="M83.182,29.413L83.182,29.413c-1.448,0-2.62,1.173-2.62,2.62c0,0,0,0,0,0.001c0,1.446,1.172,2.62,2.619,2.62  s2.619-1.173,2.619-2.62C85.801,30.587,84.629,29.414,83.182,29.413z"
    />
    <path
      d="M74.598,13.615c-10.238,0-18.538,8.299-18.538,18.538v0c0,10.237,8.3,18.536,18.538,18.536  c10.236,0,18.536-8.299,18.536-18.536C93.134,21.916,84.836,13.616,74.598,13.615z M68.633,32.034c0,1.446-1.173,2.62-2.62,2.62  c-1.446,0-2.619-1.173-2.619-2.62c0-1.447,1.171-2.62,2.618-2.621c0.001,0,0.001,0,0.001,0  C67.46,29.413,68.633,30.586,68.633,32.034C68.633,32.033,68.633,32.033,68.633,32.034z M83.14,41.058  c-5.258-0.117-7.256-2.892-8.463-6.481c-0.069-6.999-2.943-11.127-9.188-11.127c-2.712,0-5.13,1.588-6.824,4.074  c2.059-7.089,8.551-11.967,15.933-11.973v0c9.169,0,16.603,7.433,16.603,16.603c-0.004,0.999-0.099,1.994-0.282,2.976  C88.803,40.03,85.78,41.053,83.14,41.058z"
    />
    <path
      d="M9.162,41.549h13.892l6.886,11.929l6.887-11.929h14.537L44.096,28.96l6.946-12.032H37.149L30.263,5V5l-6.887,11.929H8.839  l7.268,12.589L9.162,41.549z M14.047,38.728l3.689-6.39l1.654,2.865l2.034,3.525H14.047z M29.94,47.835l-3.629-6.287h3.953h3.308  L29.94,47.835z M46.478,38.728h-8.022l2.036-3.525l1.976-3.423L46.478,38.728z M46.156,19.751l-3.689,6.389l-1.654-2.864  l-2.035-3.524H46.156z M30.263,10.643l3.629,6.287H29.94h-3.306L30.263,10.643z M25.004,19.751h4.936h5.581l2.849,4.934l2.469,4.275  l-2.791,4.833l-2.849,4.935h-4.936h-5.581l-2.849-4.935l-2.468-4.274l2.79-4.833L25.004,19.751z M13.725,19.751h8.022l-2.036,3.524  l-1.976,3.422L13.725,19.751z"
    />
    <polygon
      points="59.514,71.137 62.85,76.608 63.281,70.213 69.516,68.731 63.567,66.345 64.085,59.958 59.978,64.878 54.062,62.412   57.472,67.838 53.3,72.702 "
    />
  </svg>
</template>
