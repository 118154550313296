<script setup lang="ts">
import { useAuthStore } from "@/store/useAuthStore";

const authStore = useAuthStore();
</script>

<template>
  <router-view />
</template>

<style lang="scss">
body {
  background-color: #171717;
}
</style>
