<script setup lang="ts">
import StatsButtonMenu from "@/components/Menus/StatsButtonMenu.vue";
import { useMapStore } from "@/store/useMapStore";
import { computed } from "vue";

const mapStore = useMapStore();

const countriesVisitedStat = computed(() => {
  return mapStore.countriesVisitedStats;
});
</script>

<template>
  <div class="grid grid-cols-3">
    <StatsButtonMenu
      v-memo="[countriesVisitedStat.onu]"
      :text1="countriesVisitedStat.onu"
      text2="Countries"
      class="mx-auto"
    />
    <StatsButtonMenu
      v-memo="[countriesVisitedStat.area]"
      :text1="countriesVisitedStat.area"
      text2="Of the world"
      class="mx-auto"
    />
    <StatsButtonMenu
      v-memo="[countriesVisitedStat.continent]"
      :text1="countriesVisitedStat.continent"
      text2="Continent"
      class="mx-auto"
    />
  </div>
</template>
