<script setup lang="ts">
import { computed, defineProps, Ref, ref, watch } from "vue";
import { useMapStore } from "@/store/useMapStore";
import { useCountriesStore } from "@/store/useCountriesStore";
import { StatusCountry } from "@/interfaces/StatusCountry";
import BottomSheet from "@/components/Menus/BottomSheet.vue";
import TitleBig from "@/components/Titles/TitleBig.vue";
import TextMedium from "@/components/Texts/TextMedium.vue";
import SunIcon from "@/components/Icons/SunIcon.vue";
import GrayCaseForMenu from "@/components/Menus/GrayCaseForMenu.vue";
import UnvisitedIcon from "@/components/Icons/UnvisitedIcon.vue";
import VisitedIcon from "@/components/Icons/VisitedIcon.vue";
import ToVisitIcon from "@/components/Icons/ToVisitIcon.vue";
import LanguageIcon from "@/components/Icons/LanguageIcon.vue";
import TitleMedium from "@/components/Titles/TitleMedium.vue";
import PopulationIcon from "@/components/Icons/PopulationIcon.vue";
import CurrencyIcon from "@/components/Icons/CurrencyIcon.vue";
import ContinentIcon from "@/components/Icons/ContinentIcon.vue";
import ReligionIcon from "@/components/Icons/ReligionIcon.vue";
import IconAndTextCountryInformationMenu from "@/components/Menus/IconAndTextCountryInformationMenu.vue";
import TextSmall from "@/components/Texts/TextSmall.vue";
import GlobeIcon from "@/components/Icons/GlobeIcon.vue";
import FlagIcon from "@/components/Icons/FlagIcon.vue";
import CupHotIcon from "@/components/Icons/CupHotIcon.vue";
import TelephoneIcon from "@/components/Icons/TelephoneIcon.vue";
import PcDisplayIcon from "@/components/Icons/PcDisplayIcon.vue";

const props = defineProps<{
  selectedCountry: string;
  dialogId: number;
}>();

const userStore = useMapStore();
const countriesStore = useCountriesStore();

const lastUpdate: Ref<Date> = ref(new Date(2023, 10, 18));
const formattedData = computed(() => {
  // Extraction du jour, du mois et de l'année
  const day = lastUpdate.value.getDate();
  const month = lastUpdate.value.toLocaleString("en", { month: "long" });
  const year = lastUpdate.value.getFullYear();

  return `${day} ${month} ${year}`;
});

const informationsCountryApi = ref(null);

const informationsCountryJson = computed(() => {
  if (props.selectedCountry) {
    return countriesStore.getCountryById(props.selectedCountry);
  } else {
    return null;
  }
});

const fetchData = async () => {
  try {
    const response = await fetch(
      `https://restcountries.com/v3.1/alpha/${props.selectedCountry}`
    );
    const data = await response.json();
    lastUpdate.value = new Date();
    return data[0];
  } catch (error) {
    lastUpdate.value = new Date(2023, 10, 18);
    return null;
  }
};

watch(
  () => props.selectedCountry,
  async () => {
    informationsCountryApi.value = await fetchData();
  },
  { immediate: true }
);

const informationsUser = computed(() => {
  if (props.selectedCountry) {
    return userStore.getCountryById(props.selectedCountry);
  } else {
    return null;
  }
});

const updateStatus = (status: StatusCountry) => {
  userStore.updateCountryStatusByCode(props.selectedCountry, status);
};

function formatPopulation(population) {
  if (population >= 1000000000) {
    return (population / 1000000000).toFixed(1) + "B";
  } else if (population >= 1000000) {
    return (population / 1000000).toFixed(1) + "M";
  } else if (population >= 1000) {
    return (population / 1000).toFixed(1) + "K";
  } else {
    return population.toString();
  }
}

const realCountry = computed(() => {
  return countriesStore.getCountryById(
    informationsCountryJson.value.realCountry
  ).name;
});
</script>

<template>
  <BottomSheet
    :dialog-id="props.dialogId"
    :all-draggable="false"
    :out-side-closable="true"
    class="z-50"
  >
    <!-- country informations -->
    <div class="flex flex-col space-y-8 flex-1 p-4 pt-0">
      <div class="flex flex-row w-full items-center">
        <div class="w-2/12">
          <img
            class="w-full h-auto"
            :src="informationsCountryJson.flag"
            :alt="
              informationsCountryApi !== null
                ? informationsCountryApi.name.common
                : informationsCountryJson.name
            "
          />
        </div>
        <div class="w-10/12 md:w-5/6 flex flex-col space-y-2 ml-4">
          <TitleBig
            :text="
              informationsCountryApi !== null
                ? informationsCountryApi.name.common
                : informationsCountryJson.name
            "
          />
          <hr />
          <TextMedium
            :text="
              informationsCountryApi !== null
                ? informationsCountryApi.capital[0]
                : informationsCountryJson.capital
            "
          />
        </div>
      </div>
      <!-- visited options -->
      <div class="w-full grid grid-cols-3 gap-4">
        <GrayCaseForMenu
          :status="StatusCountry.Unvisited"
          :country-code="props.selectedCountry"
          :with-text="true"
        />
        <GrayCaseForMenu
          :status="StatusCountry.Visited"
          :country-code="props.selectedCountry"
          :with-text="true"
        />
        <GrayCaseForMenu
          :status="StatusCountry.ToVisit"
          :country-code="props.selectedCountry"
          :with-text="true"
        />
      </div>
      <TextSmall
        class="w-full text-right"
        :text="'Last update : ' + formattedData"
      />
      <div class="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-8">
        <!-- country informations -->
        <IconAndTextCountryInformationMenu
          title="Languages"
          :text="
            informationsCountryApi !== null
              ? Object.values(informationsCountryApi.languages).join(', ')
              : informationsCountryJson.languages.join(', ')
          "
        >
          <LanguageIcon class="w-full h-auto text-white" />
        </IconAndTextCountryInformationMenu>
        <IconAndTextCountryInformationMenu
          title="Population"
          :text="
            informationsCountryApi !== null
              ? formatPopulation(informationsCountryApi.population.toString())
              : formatPopulation(informationsCountryJson.population.toString())
          "
        >
          <PopulationIcon class="w-full h-auto text-white" />
        </IconAndTextCountryInformationMenu>
        <IconAndTextCountryInformationMenu
          title="Currency"
          :text="
            informationsCountryApi !== null
              ? `${Object.values(informationsCountryApi.currencies)[0].name} (${
                  Object.values(informationsCountryApi.currencies)[0].symbol
                })`
              : informationsCountryJson.currency.name
          "
        >
          <CurrencyIcon class="w-full h-auto text-white" />
        </IconAndTextCountryInformationMenu>
        <IconAndTextCountryInformationMenu
          title="Continent"
          :text="
            informationsCountryApi !== null
              ? informationsCountryApi.continents.join(', ')
              : informationsCountryJson.continent
          "
        >
          <ContinentIcon class="w-full h-auto text-white" />
        </IconAndTextCountryInformationMenu>
        <IconAndTextCountryInformationMenu
          title="Religion"
          :text="informationsCountryJson.religion"
        >
          <ReligionIcon class="w-full h-auto text-white" />
        </IconAndTextCountryInformationMenu>
        <IconAndTextCountryInformationMenu
          title="Area"
          :text="
            informationsCountryApi !== null
              ? `${informationsCountryApi.area} km²`
              : `${informationsCountryJson.area} km²`
          "
        >
          <GlobeIcon class="w-full h-auto text-white" />
        </IconAndTextCountryInformationMenu>
        <IconAndTextCountryInformationMenu
          v-if="
            informationsCountryJson !== null &&
            informationsCountryJson.independance
          "
          title="Independent"
          :text="informationsCountryJson.independance"
        >
          <FlagIcon class="w-full h-auto text-white" />
        </IconAndTextCountryInformationMenu>
        <IconAndTextCountryInformationMenu
          v-if="
            informationsCountryJson !== null &&
            informationsCountryJson.realCountry
          "
          title="Country"
          :text="realCountry"
        >
          <FlagIcon class="w-full h-auto text-white" />
        </IconAndTextCountryInformationMenu>
        <IconAndTextCountryInformationMenu
          v-if="
            informationsCountryJson !== null &&
            informationsCountryJson.national_dish
          "
          title="National Dish"
          :text="informationsCountryJson.national_dish"
        >
          <CupHotIcon class="w-full h-auto text-white" />
        </IconAndTextCountryInformationMenu>
        <IconAndTextCountryInformationMenu
          v-if="
            informationsCountryJson !== null &&
            informationsCountryJson.dialling_code
          "
          title="Dialling code"
          :text="informationsCountryJson.dialling_code"
        >
          <TelephoneIcon class="w-full h-auto text-white" />
        </IconAndTextCountryInformationMenu>
        <IconAndTextCountryInformationMenu
          v-if="
            informationsCountryJson !== null &&
            informationsCountryJson.domain_name
          "
          title="Domain"
          :text="informationsCountryJson.domain_name"
        >
          <PcDisplayIcon class="w-full h-auto text-white" />
        </IconAndTextCountryInformationMenu>
      </div>
    </div>
  </BottomSheet>
</template>
