<script setup lang="ts">
import WorldMapSvg from "@/components/WorldMapSvg.vue";
import ThemeButtonMenu from "@/components/Menus/ThemeButtonMenu.vue";
import { computed, ref } from "vue";
import MapCaseForMenu from "@/components/Menus/MapCaseForMenu.vue";
import { useMapThemeStore } from "@/store/useMapThemeStore";
import { useAuthStore } from "@/store/useAuthStore";
import { useDialogsStore } from "@/store/useDialogsStore";
import SearchButtonMenu from "@/components/Menus/SearchButtonMenu.vue";
import LeftButtonMenu from "@/components/Menus/LeftButtonMenu.vue";
import BottomSheet from "@/components/Menus/BottomSheet.vue";
import { useCountriesStore } from "@/store/useCountriesStore";
import StatsButtonMenuContainer from "@/components/Menus/StatsButtonMenuContainer.vue";
import CountryListMenu from "@/components/Menus/CountryListMenu.vue";
import PaletteMenu from "@/components/Menus/PaletteMenu.vue";
import { useMapStore } from "@/store/useMapStore";
import SideBar from "@/components/Menus/SideBar.vue";
import UserManageView from "@/components/Friends/UserManageView.vue";
import { useFriendsStore } from "@/store/useFriendsStore";
import UserManageViewContainer from "@/components/Friends/UserManageViewContainer.vue";
import UserAddViewContainer from "@/components/Friends/UserAddViewContainer.vue";
import UserNotificationsViewContainer from "@/components/Friends/UserNotificationsViewContainer.vue";
import ButtonPrimary from "@/components/Buttons/ButtonPrimary.vue";
import { $ } from "vue/macros";
import InputText from "@/components/Form/InputText.vue";
import ButtonSecondary from "@/components/Buttons/ButtonSecondary.vue";
const dialogsStore = useDialogsStore();
const countriesStore = useCountriesStore();
const authStore = useAuthStore();
const friendsStore = useFriendsStore();

const themeColorMenu = dialogsStore.createNewDialog();
const statsMenu = dialogsStore.createNewDialog();
const friendsMenu = dialogsStore.createNewDialog();
const searchMenu = dialogsStore.createNewDialog();

const openThemeColorMenu = () => {
  dialogsStore.openModal(themeColorMenu);
};

const openStatsMenu = () => {
  dialogsStore.openModal(statsMenu);
};

const openFriendsMenu = () => {
  dialogsStore.openModal(friendsMenu);
};

const openSearchMenu = () => {
  dialogsStore.openModal(searchMenu);
};

const mapThemeStore = useMapThemeStore();
const themes = mapThemeStore.themes;
const themeSelected = computed(() => {
  return authStore.currentUser?.selectedTheme;
});

const updateThemeSelected = (name: string) => {
  authStore.updateTheme(name);
};

const mapStore = useMapStore();

const allContinents = mapStore.getAllContinents();
const dialogsContinent: { continent: string; dialog: number }[] = [];

for (const country of countriesStore.countries) {
  const continent = country.continent;

  const existingDialog = dialogsContinent.find(
    (info) => info.continent === continent
  );

  if (!existingDialog) {
    dialogsContinent.push({
      continent,
      dialog: dialogsStore.createNewDialog(),
    });
  }
}

const downloadSvg = () => {
  const svgElement = document.getElementById("worldMap");

  const transform = svgElement.style.transform;

  svgElement.style.transform = "none";

  const svgContent = new XMLSerializer().serializeToString(svgElement);

  const blob = new Blob([svgContent], {
    type: "image/svg+xml",
  });

  svgElement.style.transform = transform;

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = "road-explorer-map.svg";
  link.href = url;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};

const search = ref("");

const logout = () => {
  authStore.logoutUser();
};
</script>

<template>
  <div class="relative w-screen h-screen">
    <WorldMapSvg />
    <div
      class="absolute bottom-4 w-full w-full md:w-3/12 md:right-4"
      @click="openStatsMenu"
    >
      <StatsButtonMenuContainer />
    </div>
    <div class="absolute bottom-32 right-4 md:right-12">
      <PaletteMenu />
    </div>
    <div class="absolute top-4 w-full w-full md:w-3/12 grid grid-cols-12">
      <LeftButtonMenu class="col-span-2 mx-auto" @click="openFriendsMenu" />
      <SearchButtonMenu class="col-span-2 mx-auto" @click="openSearchMenu" />
      <ThemeButtonMenu class="col-span-2 mx-auto" @click="openThemeColorMenu" />
    </div>
    <BottomSheet
      :dialog-id="themeColorMenu"
      class="h-auto"
      :all-draggable="false"
      :out-side-closable="true"
    >
      <div class="grid md:grid-cols-3 grid-cols-2">
        <MapCaseForMenu
          v-for="theme in themes"
          :key="theme.name"
          :active="theme.name === themeSelected"
          :color="theme.visited"
          :background-color="theme.background"
          @click="updateThemeSelected(theme.name)"
          @touch="updateThemeSelected(theme.name)"
        />
      </div>
    </BottomSheet>
    <BottomSheet
      :dialog-id="statsMenu"
      :all-draggable="false"
      :out-side-closable="true"
    >
      <StatsButtonMenuContainer />
      <div class="max-h-[600px] h-auto overflow-y-auto mt-4">
        <CountryListMenu
          v-for="continent in allContinents"
          :key="continent"
          :name="continent"
          :dialogId="
            dialogsContinent.find(
              (continentDialog) => continentDialog.continent === continent
            )?.dialog
          "
          class="mb-4"
        />
      </div>
    </BottomSheet>
    <SideBar :dialog-id="friendsMenu" :out-side-closable="true">
      <div class="w-full h-full pb-4 overflow-y-auto flex flex-col space-y-16">
        <div class="flex flex-col space-y-4">
          <UserManageViewContainer />
        </div>
        <div class="flex flex-col space-y-4">
          <UserAddViewContainer />
        </div>
        <div class="flex flex-col space-y-4">
          <UserNotificationsViewContainer />
        </div>
        <div class="w-full flex flex-col space-y-4">
          <ButtonPrimary @click="downloadSvg" text="Download SVG" />
          <hr />
          <ButtonSecondary @click="logout" text="Logout" />
        </div>
      </div>
    </SideBar>
    <BottomSheet
      :dialog-id="searchMenu"
      :all-draggable="false"
      :out-side-closable="true"
    >
      <InputText
        v-model="search"
        name="search"
        placeholder="Search country"
        id="search"
        is_required="false"
        type="text"
      />
      <div class="max-h-[600px] h-auto overflow-y-auto mt-4">
        <CountryListMenu
          v-for="continent in allContinents"
          :key="continent"
          :name="continent"
          :dialogId="
            dialogsContinent.find(
              (continentDialog) => continentDialog.continent === continent
            )?.dialog
          "
          :search="search"
          class="mb-4"
        />
      </div>
    </BottomSheet>
  </div>
</template>
