<script setup lang="ts">
import { computed, ref } from "vue";
import { useFriendsStore } from "@/store/useFriendsStore";
import { useAuthStore } from "@/store/useAuthStore";
import TitleMedium from "@/components/Titles/TitleMedium.vue";
import InputText from "@/components/Form/InputText.vue";
import ButtonPrimary from "@/components/Buttons/ButtonPrimary.vue";
import TextSmall from "@/components/Texts/TextSmall.vue";
import FriendRequestManageView from "@/components/Friends/FriendRequestManageView.vue";

const friendsStore = useFriendsStore();
const authStore = useAuthStore();

const nameValue = ref("");

const error = ref(false);
const success = ref(false);

const addFriend = () => {
  error.value = false;
  success.value = false;
  const isSuccess = friendsStore.addFriendRequest(
    authStore.currentUser.id,
    nameValue.value
  );
  if (isSuccess) {
    success.value = true;
  } else {
    error.value = true;
  }
};

const friendsRequestFromMe = computed(() => {
  return friendsStore.friendRequestsFromMe;
});
</script>

<template>
  <div class="flex flex-col space-y-4">
    <TitleMedium text="My requests" />
    <div class="flex flex-col space-y-4">
      <InputText
        name="name"
        :is_required="true"
        id="name"
        v-model="nameValue"
        placeholder="Name"
        type="text"
      />
      <ButtonPrimary text="Add" @click="addFriend" />
      <TextSmall
        v-if="success"
        text="The friend request has been successfully sent !"
        class="text-green-300"
      />
      <TextSmall
        v-if="error"
        text="This user does not exist"
        class="text-red-500"
      />
    </div>
    <div
      v-if="friendsRequestFromMe.length > 0"
      class="flex flex-col w-full space-y-4"
    >
      <FriendRequestManageView
        v-for="friend in friendsRequestFromMe"
        :key="friend.to"
        :friends-request="friend"
      />
    </div>
  </div>
</template>
