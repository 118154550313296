import { defineStore } from "pinia";
import { Theme } from "@/interfaces/Theme";
import themesData from "../json/themes.json";

export const useMapThemeStore = defineStore("mapTheme", () => {
  const themes: Theme[] = themesData;

  function getThemeById(id: number) {
    return themes.find((theme, index) => index === id);
  }

  function getThemeByName(name: string) {
    return themes.find((theme) => theme.name === name);
  }

  return {
    themes,
    getThemeById,
    getThemeByName,
  };
});
