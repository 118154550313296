<script setup lang="ts">
import { useFriendsStore } from "@/store/useFriendsStore";
import { computed } from "vue";

const friendsStore = useFriendsStore();

const numberNotifications = computed(() => {
  return friendsStore.friendRequestsToMe.length;
});
</script>

<template>
  <svg
    width="53"
    height="54"
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="27.5" cy="27.5" r="27.5" fill="url(#paint0_linear_262_2570)" />
    <ellipse cx="27.5002" cy="27.5" rx="24.3571" ry="24.3571" fill="#262626" />
    <path d="M40 15H15V19.1667H40V15Z" fill="white" />
    <path d="M32.8571 25.4173H15V29.584H32.8571V25.4173Z" fill="white" />
    <path d="M25.7143 35.8332H15V39.9999H25.7143V35.8332Z" fill="white" />
    <ellipse
      v-if="numberNotifications > 0"
      cx="44.75"
      cy="43.212"
      rx="11"
      ry="10.8866"
      fill="url(#paint1_linear_262_2570)"
    />
    <text
      v-if="numberNotifications > 0"
      x="80%"
      y="78%"
      text-anchor="middle"
      alignment-baseline="middle"
      fill="white"
      font-size="15"
    >
      {{ numberNotifications }}
    </text>
    <defs>
      <linearGradient
        id="paint0_linear_262_2570"
        x1="27.5"
        y1="0"
        x2="27.5"
        y2="55"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#60A5FA" />
        <stop offset="1" stop-color="#8B5DF1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_262_2570"
        x1="44.75"
        y1="32.3254"
        x2="44.75"
        y2="54.0986"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#60A5FA" />
        <stop offset="1" stop-color="#8B5DF1" />
      </linearGradient>
    </defs>
  </svg>
</template>
