import { defineStore } from "pinia";
import { useAuthStore } from "@/store/useAuthStore";
import { StatusCountry } from "@/interfaces/StatusCountry";
import { useUsersStore } from "@/store/useUsersStore";
import { computed, ComputedRef, Ref } from "vue";
import { Theme } from "@/interfaces/Theme";
import { useMapThemeStore } from "@/store/useMapThemeStore";
import { LegendColor } from "@/interfaces/LegendColor";
import { useCountriesStore } from "@/store/useCountriesStore";
import { useFriendsStore } from "@/store/useFriendsStore";

// https://medium.com/@jamiecarter7/getactivepinia-was-called-with-no-active-pinia-after-updating-nuxt3-and-pinia-104b33dce97e
export const useMapStore = defineStore("user", () => {
  function getCountryById(code: string) {
    const authStore = useAuthStore();
    return authStore.currentUser?.countries.find(
      (countryWithStatus) => countryWithStatus.countryCode === code
    );
  }

  function updateCountryStatusByCode(code: string, status: StatusCountry) {
    useAuthStore().updateCountryStatusByCode(code, status);
  }

  function getCountryByIdAndUser(code: string, userId: number) {
    const usersStore = useUsersStore();
    return usersStore
      .getUserById(userId)
      ?.countries.find(
        (countryWithStatus) => countryWithStatus.countryCode === code
      );
  }

  const countriesListStatuts = computed(() => {
    // Store needed
    const mapThemeStore = useMapThemeStore();
    const authStore = useAuthStore();
    const countriesStore = useCountriesStore();
    const friendsStore = useFriendsStore();

    // get theme color from current user
    const theme: Ref<Theme | undefined> = computed(() => {
      if (authStore.currentUser) {
        return mapThemeStore.getThemeByName(
          authStore.currentUser.selectedTheme
        );
      } else {
        return mapThemeStore.themes[0];
      }
    });

    const selectedUsers = computed(() => {
      return authStore.currentUser?.selectedUsers;
    });

    // array for store countries infos
    const countriesStatutsAndColor: {
      code: string;
      statut: StatusCountry;
      color?: string;
    }[] = [];

    // Push all countries status and color
    // color adaptation depends on the number of users selected and the number of people who have traveled on it
    let finalColor: string | undefined = theme.value?.unvisited;
    let finalStatut: StatusCountry = StatusCountry.Unvisited;

    for (const country of countriesStore.countries) {
      finalStatut = StatusCountry.Unvisited;
      finalColor = theme.value?.unvisited;

      if (selectedUsers.value?.length === 1) {
        const userCountry = getCountryByIdAndUser(
          country.code,
          selectedUsers.value[0]
        );
        if (userCountry && userCountry.status) {
          switch (userCountry.status) {
            case StatusCountry.Visited:
              finalColor = theme.value?.visited;
              finalStatut = StatusCountry.Visited;
              break;
            case StatusCountry.ToVisit:
              finalStatut = StatusCountry.ToVisit;
              finalColor = theme.value?.toVisit;
              break;
            default:
              break;
          }
        }
      } else if (selectedUsers.value && selectedUsers.value.length > 1) {
        // check if 0, 1 or more selected users have traveled to this country
        const selectedUsersVisited = getCountryStatutDependSelectedUsers(
          country.code
        );

        if (selectedUsersVisited.length === 1) {
          if (!authStore.currentUser?.common) {
            // userColor or friendColor
            let userColor = "";
            if (selectedUsersVisited[0] === authStore.currentUser?.id) {
              userColor = authStore.currentUser.color;
            } else {
              const confirmedFriendRequest = friendsStore.friendRequests.find(
                (request) =>
                  (request.from === authStore.currentUser?.id ||
                    request.to === authStore.currentUser?.id) &&
                  (request.from === selectedUsersVisited[0] ||
                    request.to === selectedUsersVisited[0]) &&
                  request.confirmed
              );

              if (confirmedFriendRequest) {
                if (confirmedFriendRequest.from === authStore.currentUser?.id) {
                  userColor = confirmedFriendRequest.colorTo;
                } else {
                  userColor = confirmedFriendRequest.colorFrom;
                }
              }
            }
            if (userColor) {
              finalStatut = StatusCountry.Visited;
              finalColor = userColor;
            }
          }
        } else if (selectedUsersVisited.length > 1) {
          finalColor = theme.value?.common;
          finalStatut = StatusCountry.Visited;
        }
      }
      countriesStatutsAndColor.push({
        code: country.code,
        statut: finalStatut,
        color: finalColor,
      });
    }

    return countriesStatutsAndColor;
  });

  function getCountryStatutDependSelectedUsers(code: string): number[] {
    const authStore = useAuthStore();
    const users = authStore.currentUser?.selectedUsers
      ? authStore.currentUser?.selectedUsers
      : [];
    const usersVisited = [];
    for (const userId of users) {
      const status = getCountryByIdAndUser(code, userId)?.status;
      switch (status) {
        case StatusCountry.Visited:
          usersVisited.push(userId);
          break;
        default:
          break;
      }
    }
    return usersVisited;
  }

  const legendsColor: ComputedRef<LegendColor[]> = computed(() => {
    const legendColors: LegendColor[] = [];
    const usersStore = useUsersStore();
    const mapThemeStore = useMapThemeStore();
    const authStore = useAuthStore();
    // get theme color from current user
    const theme: Ref<Theme | undefined> = computed(() => {
      if (authStore.currentUser) {
        return mapThemeStore.getThemeByName(
          authStore.currentUser.selectedTheme
        );
      } else {
        return mapThemeStore.themes[0];
      }
    });
    // color adaptation depends on the number of users selected
    const selectedUsers = computed(() => {
      return authStore.currentUser?.selectedUsers;
    });
    // legend colors
    /*legendColors.push({
      name: "Arrière-plan",
      color: theme.value?.background,
    });*/
    legendColors.push({
      name: "Non visité",
      color: theme.value?.unvisited,
    });
    if (selectedUsers.value && selectedUsers.value?.length <= 1) {
      legendColors.push({
        name: "Visité",
        color: theme.value?.visited,
      });
      legendColors.push({
        name: "A Visité",
        color: theme.value?.toVisit,
      });
    } else if (selectedUsers.value && selectedUsers.value.length > 1) {
      for (const userId of selectedUsers.value) {
        // user color
        const user = usersStore.getUserById(userId);
        legendColors.push({
          name: user?.name,
          color: user?.color,
        });
      }
      legendColors.push({
        name: "Commun",
        color: theme.value?.common,
      });
    }
    /*legendColors.push({
      name: "Bordure",
      color: theme.value?.border,
    });*/
    legendColors.push({
      name: "Survol",
      color: theme.value?.hover,
    });
    return legendColors;
  });

  const countriesVisitedStats: ComputedRef<{
    area: string;
    continent: string;
    onu: string;
  }> = computed(() => {
    const countriesStore = useCountriesStore();
    const numberOnuCountries: number = countriesStore.onuCountries.length;
    let numberCountriesVisited = 0;
    let areaAllCountries = 0;
    let areaCountriesVisited = 0;
    const continentsVisited = new Set();

    for (const onuCountry of countriesStore.onuCountries) {
      const country = countriesListStatuts.value.find(
        (country) => country.code === onuCountry
      );
      if (
        country?.statut === StatusCountry.Visited ||
        country?.statut === StatusCountry.Common
      ) {
        numberCountriesVisited++;
      }
    }

    for (const countryStore of countriesStore.countries) {
      areaAllCountries += countryStore.area;
      const country = countriesListStatuts.value.find(
        (country) => country.code === countryStore.code
      );
      if (
        country?.statut === StatusCountry.Visited ||
        country?.statut === StatusCountry.Common
      ) {
        areaCountriesVisited += countryStore.area;
        continentsVisited.add(countryStore.continent);
      }
    }

    const percentage = (areaCountriesVisited / areaAllCountries) * 100;

    return {
      onu: `${numberCountriesVisited}/${numberOnuCountries}`,
      area: `${percentage.toFixed(0)}%`,
      continent: `${continentsVisited.size}/${getAllContinents().size}`,
    };
  });

  function getAllContinents(): Set<string> {
    const countriesStore = useCountriesStore();
    const allContinents: Set<string> = new Set<string>();
    for (const country of countriesStore.countries) {
      const continent = country.continent;
      allContinents.add(continent);
    }
    return allContinents;
  }

  return {
    getCountryById,
    countriesListStatuts,
    updateCountryStatusByCode,
    legendsColor,
    countriesVisitedStats,
    getAllContinents,
  };
});
