<script setup lang="ts">
import InputText from "@/components/Form/InputText.vue";
import { computed, ref } from "vue";
import ButtonBase from "@/components/Buttons/ButtonPrimary.vue";
import { useAuthStore } from "@/store/useAuthStore";
import TextError from "@/components/Texts/TextError.vue";
const authStore = useAuthStore();
import { useErrorsStore } from "@/store/useErrorsStore";
import RouterLinkCustom from "@/components/Texts/RouterLinkCustom.vue";
import TitleBig from "@/components/Titles/TitleBig.vue";
const errorsStore = useErrorsStore();

const nameValue = ref("");
const passwordValue = ref("");

const tryLogin = () => {
  authStore.loginUser(nameValue.value, passwordValue.value);
};

const errorMessage = computed(() => {
  const errorInput = errorsStore.getErrorByName("login");
  if (errorInput) {
    return errorInput.message;
  }
  return "";
});
</script>

<template>
  <div
    class="container mx-auto px-8 flex flex-row md:items-center md:justify-center h-screen mt-8 md:mt-0"
  >
    <div class="w-full md:w-2/3 mx-auto flex flex-col space-y-8">
      <TitleBig text="Login" />
      <div
        class="grid grid-cols-1 md:grid-cols-2 md:gap-x-8 gap-y-4 md:gap-y-8"
      >
        <InputText
          name="name"
          :is_required="true"
          id="name"
          v-model="nameValue"
          placeholder="Name"
          type="text"
        />
        <InputText
          name="password"
          :is_required="true"
          id="password"
          v-model="passwordValue"
          placeholder="Password"
          type="password"
        />
      </div>
      <TextError v-if="errorMessage" :text="errorMessage" />
      <ButtonBase
        class="w-1/2 md:w-1/4 m-auto md:m-0"
        text="Sign in"
        @click="tryLogin"
      />
      <RouterLinkCustom to="/register" text="Not yet registered ?" />
    </div>
  </div>
</template>
