import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { Error } from "@/interfaces/Error";
import { User } from "@/interfaces/User";

export const useErrorsStore = defineStore("errors", () => {
  const errors: Ref<Error[]> = ref([]);

  function getErrorByName(name: string) {
    return errors.value.find((error) => error.name === name);
  }

  function addError(name: string, message: string) {
    const error: Error = { name, message };
    errors.value.push(error);
    return errors.value.length - 1;
  }

  function deleteAllErrors() {
    errors.value.length = 0;
  }

  return {
    errors,
    getErrorByName,
    addError,
    deleteAllErrors,
  };
});
