import { defineStore } from "pinia";
import { User } from "@/interfaces/User";
import { useCountriesStore } from "@/store/useCountriesStore";
import { useUsersStore } from "@/store/useUsersStore";
import { computed, ref, Ref } from "vue";
import { useMapThemeStore } from "@/store/useMapThemeStore";
import router from "@/router";
import { sha256 } from "js-sha256";
import { useErrorsStore } from "@/store/useErrorsStore";
import { StatusCountry } from "@/interfaces/StatusCountry";

// https://medium.com/@jamiecarter7/getactivepinia-was-called-with-no-active-pinia-after-updating-nuxt3-and-pinia-104b33dce97e
export const useAuthStore = defineStore("auth", () => {
  const currentUser: Ref<User | null> = ref(null);

  function registerUser(
    name: string,
    email: string,
    color: string,
    password: string,
    repeatPassword: string
  ) {
    const errorsStore = useErrorsStore();
    const errors = computed(() => {
      return errorsStore.errors;
    });
    errorsStore.deleteAllErrors();
    const themesStore = useMapThemeStore();
    const countriesStore = useCountriesStore();
    const usersStore = useUsersStore();
    const users = computed(() => {
      return usersStore.users;
    });
    if (usersStore.getUserByName(name)) {
      errorsStore.addError("name", "This username already exists");
    }
    if (usersStore.getUserByEmail(email)) {
      errorsStore.addError("email", "This email already exists");
    }
    if (password !== repeatPassword) {
      errorsStore.addError("password", "Passwords don't match");
    }
    if (errors.value.length === 0) {
      const id = usersStore.addUser({
        id: users.value.length + 1,
        name: name,
        email: email,
        password: sha256(password),
        selectedUsers: [users.value.length + 1],
        color: color,
        countries: countriesStore.generateCountryStatusesBase(),
        selectedTheme: themesStore.themes[0].name,
        common: false,
      });
      loginUser(email, password);
    }
  }

  function loginUser(email: string, password: string) {
    // check si user comme tel
    const usersStore = useUsersStore();
    const user = usersStore.getUserByEmailAndPassword(email, sha256(password));
    if (user) {
      currentUser.value = user;
      router.push("/world-map");
    } else {
      const errorsStore = useErrorsStore();
      errorsStore.addError(
        "login",
        "These identifiers do not match our records."
      );
      currentUser.value = null;
    }
  }

  function logoutUser() {
    currentUser.value = null;
    router.push("/login");
  }

  function getCountryById(code: string) {
    return currentUser.value?.countries.find(
      (countryWithStatus) => countryWithStatus.countryCode === code
    );
  }

  function updateTheme(name: string) {
    if (currentUser.value) {
      currentUser.value.selectedTheme = name;
    }
  }

  function updateColor(color: string) {
    if (currentUser.value) {
      currentUser.value.color = color;
    }
  }

  function updateCountryStatusByCode(code: string, status: StatusCountry) {
    const selectedCountry = currentUser.value?.countries.find(
      (countryWithStatus) => countryWithStatus.countryCode === code
    );
    if (selectedCountry) {
      selectedCountry.status = status;
    }
  }

  function updateSelectedUsers(selectedUsers: number[]) {
    const authStore = useAuthStore();
    if (authStore.currentUser) {
      authStore.currentUser.selectedUsers = selectedUsers;
    }
  }

  function updateCommon(isChecked: boolean) {
    const authStore = useAuthStore();
    if (authStore.currentUser) {
      authStore.currentUser.common = isChecked;
    }
  }

  return {
    currentUser,
    registerUser,
    loginUser,
    logoutUser,
    getCountryById,
    updateTheme,
    updateCountryStatusByCode,
    updateSelectedUsers,
    updateColor,
    updateCommon,
  };
});
