<script setup lang="ts">
import { computed, defineProps } from "vue";
import { useDialogsStore } from "@/store/useDialogsStore";
import CrossIcon from "@/components/Icons/CrossIcon.vue";

const dialogsStore = useDialogsStore();

const props = defineProps<{
  dialogId: number;
  outSideClosable: boolean;
}>();

const isOpen = computed<boolean>(() => {
  return dialogsStore.dialogs[props.dialogId];
});
</script>

<template>
  <div
    v-if="isOpen"
    class="z-50 fixed inset-0 h-full flex items-center justify-center"
  >
    <!-- Fond semi-transparent pour obscurcir le contenu principal -->
    <div
      v-if="props.outSideClosable"
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity bottom-0"
      @click="dialogsStore.closeAllModals()"
    ></div>
    <div
      v-else
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity bottom-0"
    ></div>
    <!-- Contenu du menu -->
    <div
      class="bg-custom-primaryBlack w-8/12 h-full md:w-3/12 p-4 absolute left-0 pt-12"
    >
      <!-- Fermer le menu -->
      <div
        @click="dialogsStore.closeAllModals()"
        class="cursor-pointer absolute top-2 right-2 text-white hover:text-custom-middleGray"
      >
        <CrossIcon class="text-white h-6 w-6" />
      </div>
      <slot />
    </div>
  </div>
</template>
