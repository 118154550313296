<script setup lang="ts">
import AvatarWithColor from "@/components/Friends/AvatarWithColor.vue";
import TextMedium from "@/components/Texts/TextMedium.vue";
import { useUsersStore } from "@/store/useUsersStore";
import { defineProps, defineEmits, watch } from "vue";
import ThreeDotsIcon from "@/components/Icons/ThreeDotsIcon.vue";
import InputCheckbox from "@/components/Form/InputCheckbox.vue";
import { computed, ComputedRef, ref } from "vue";
import { useAuthStore } from "@/store/useAuthStore";
import { FriendInfos } from "@/interfaces/FriendInfos";
import ButtonSecondary from "@/components/Buttons/ButtonSecondary.vue";
import InputColor from "@/components/Form/InputColor.vue";
import { useFriendsStore } from "@/store/useFriendsStore";
import { useDialogsStore } from "@/store/useDialogsStore";
import BottomSheet from "@/components/Menus/BottomSheet.vue";
import TitleMedium from "@/components/Titles/TitleMedium.vue";
import ButtonPrimary from "@/components/Buttons/ButtonPrimary.vue";
import { FriendRequest } from "@/interfaces/FriendRequest";
import CrossIcon from "@/components/Icons/CrossIcon.vue";
const authStore = useAuthStore();
const friendsStore = useFriendsStore();

const props = defineProps<{
  friendsRequest: FriendRequest;
}>();

const { getUserById } = useUsersStore();
const user = computed(() => {
  return getUserById(props.friendsRequest.to);
});

const color: ComputedRef<string> = computed(() => {
  return user.value ? user.value.color : "#ffffff";
});

const deleteFriendRequest = () => {
  friendsStore.deleteFriendRequest(authStore.currentUser?.id, user.value?.id);
};
</script>

<template>
  <div class="flex flex-col space-y-4 mr-4">
    <div class="flex flex-row space-x-4 items-end">
      <AvatarWithColor :color="color" class="flex-none h-12 mb-1" />
      <TextMedium class="flex-grow ml-2 md:ml-0" :text="user.name" />
      <CrossIcon class="text-white h-6" @click="deleteFriendRequest" />
    </div>
  </div>
</template>
