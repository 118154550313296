<script setup lang="ts">
import { defineEmits, defineProps } from "vue";
import LabelBase from "@/components/Texts/LabelBase.vue";

const props = defineProps<{
  name: string;
  label: string;
  id: string;
  modelValue: string;
}>();

const emit = defineEmits(["update:modelValue"]);

const emitInput = (val) => {
  emit("update:modelValue", val);
};
</script>

<template>
  <div class="flex flex-row space-x-4">
    <input
      :name="props.name"
      :id="props.id"
      :required="props.is_required"
      :value="props.modelValue"
      type="color"
      v-debounce:100="emitInput"
    />
    <LabelBase :text="props.label" />
  </div>
</template>
