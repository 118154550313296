<script setup lang="ts">
import { computed, defineProps } from "vue";
import { Country } from "@/interfaces/Country";
import TextSmall from "@/components/Texts/TextSmall.vue";
import { StatusCountry } from "@/interfaces/StatusCountry";
import GrayCaseForMenu from "@/components/Menus/GrayCaseForMenu.vue";
const props = defineProps<{
  countryInfos: Country;
}>();
</script>

<template>
  <div class="flex flex-row space-x-4 mb-8 items-center ml-4">
    <div class="flex flex-row space-x-4 w-6/12 items-center">
      <div class="w-2/6">
        <img
          class="w-full h-auto"
          :src="props.countryInfos.flag"
          :alt="props.countryInfos.name"
        />
      </div>
      <TextSmall :text="props.countryInfos.name" class="mr-auto" />
    </div>
    <div class="w-6/12 grid grid-cols-3 gap-4">
      <GrayCaseForMenu
        :status="StatusCountry.Unvisited"
        :country-code="props.countryInfos.code"
        :with-text="false"
      />
      <GrayCaseForMenu
        :status="StatusCountry.Visited"
        :country-code="props.countryInfos.code"
        :with-text="false"
      />
      <GrayCaseForMenu
        :status="StatusCountry.ToVisit"
        :country-code="props.countryInfos.code"
        :with-text="false"
      />
    </div>
  </div>
</template>
