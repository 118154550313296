<template>
  <svg
    width="56"
    height="55"
    viewBox="0 0 56 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="28"
      cy="27.5"
      rx="28"
      ry="27.5"
      fill="url(#paint0_linear_275_2501)"
    />
    <ellipse cx="28.0002" cy="27.5" rx="24.8" ry="24.3571" fill="#262626" />
    <path
      d="M15 14.8125C15 14.597 15.0856 14.3903 15.238 14.238C15.3903 14.0856 15.597 14 15.8125 14H23.9375C24.153 14 24.3597 14.0856 24.512 14.238C24.6644 14.3903 24.75 14.597 24.75 14.8125V23.3876L31.4889 16.6747C31.6412 16.5224 31.8479 16.4369 32.0633 16.4369C32.2788 16.4369 32.4854 16.5224 32.6378 16.6747L38.3821 22.4207C38.4578 22.4962 38.5178 22.5859 38.5588 22.6846C38.5997 22.7833 38.6208 22.8891 38.6208 22.996C38.6208 23.1029 38.5997 23.2087 38.5588 23.3074C38.5178 23.4061 38.4578 23.4958 38.3821 23.5712L31.6741 30.25H40.1875C40.403 30.25 40.6097 30.3356 40.762 30.488C40.9144 30.6403 41 30.847 41 31.0625V39.1875C41 39.403 40.9144 39.6096 40.762 39.762C40.6097 39.9144 40.403 40 40.1875 40H19.875C19.2347 40.0007 18.6006 39.8749 18.0091 39.6298C17.4176 39.3847 16.8804 39.0251 16.4284 38.5716C15.5299 37.6762 15.0173 36.4649 15 35.1965M24.75 34.8552L36.658 22.9927L32.0609 18.3972L24.75 25.6805V34.8569V34.8552ZM22.3125 35.125C22.3125 34.4785 22.0557 33.8585 21.5986 33.4014C21.1415 32.9443 20.5215 32.6875 19.875 32.6875C19.2285 32.6875 18.6085 32.9443 18.1514 33.4014C17.6943 33.8585 17.4375 34.4785 17.4375 35.125C17.4375 35.7715 17.6943 36.3914 18.1514 36.8486C18.6085 37.3057 19.2285 37.5625 19.875 37.5625C20.5215 37.5625 21.1415 37.3057 21.5986 36.8486C22.0557 36.3914 22.3125 35.7715 22.3125 35.125ZM39.375 38.375V31.875H30.0443L23.5199 38.375H39.375Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_275_2501"
        x1="28"
        y1="0"
        x2="28"
        y2="55"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#60A5FA" />
        <stop offset="1" stop-color="#8B5DF1" />
      </linearGradient>
    </defs>
  </svg>
</template>
