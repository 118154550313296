<script setup lang="ts">
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import SliderOval from "@/components/Slider/SliderOval.vue";
import SliderRound from "@/components/Slider/SliderRound.vue";
import TitleBig from "@/components/Titles/TitleBig.vue";
import TitleMedium from "@/components/Titles/TitleMedium.vue";
import TextSmall from "@/components/Texts/TextSmall.vue";
import ButtonPrimary from "@/components/Buttons/ButtonPrimary.vue";
import TitleExtraBig from "@/components/Titles/TitleExtraBig.vue";
import TextMedium from "@/components/Texts/TextMedium.vue";

const currentSlide = ref(0);
const slides: {
  title: string;
  text: string;
  img: string;
}[] = [
  {
    title: "Track Your Journeys",
    text: "Easily mark your visited destinations and plan your future adventures.",
    img: "slider1.svg",
  },
  {
    title: "Customize Map Colors",
    text: "Easily mark your visited destinations and plan your future adventures.",
    img: "slider2.svg",
  },
  {
    title: "Country information",
    text: "Get valuable insights about the countries you explore on your journeys.",
    img: "slider3.svg",
  },
  {
    title: "Connect with friends",
    text: "Stay connected by adding friends to your travel network.",
    img: "slider4.svg",
  },
  {
    title: "Tailor Your Map View",
    text: "Choose between your personal map or a private shared map with selected friends.",
    img: "slider5.svg",
  },
  {
    title: "Download Map as SVG",
    text: "Export your map as an SVG file for easy sharing and printing.",
    img: "slider6.svg",
  },
];

const changeSlide = (index: number) => {
  currentSlide.value = index;
};
</script>

<template>
  <div
    class="relative flex justify-center h-full w-full text-white w-full h-screen"
    id="bg-custom"
  >
    <div
      class="flex flex-col space-y-8 backdrop-blur-3xl p-8 w-full h-full overflow-x-auto"
    >
      <TitleExtraBig text="Road Explorer" class="text-left" />
      <div class="flex flex-col space-y-4 md:flex-row">
        <img
          :src="'img/slider/' + slides[currentSlide].img"
          :alt="slides[currentSlide].img"
          class="md:w-1/3 mb-4 md:mr-4"
        />
        <div class="flex flex-col space-y-8 md:pt-8">
          <TitleBig :text="slides[currentSlide].title" />
          <TextMedium :text="slides[currentSlide].text" />
          <div class="absolute bottom-8 right-8 left-8 flex flex-col space-y-8">
            <div class="flex flex-row space-x-4 mx-auto">
              <div v-for="(slide, index) of slides" :key="index">
                <SliderOval v-if="index === currentSlide" />
                <SliderRound v-else @click="changeSlide(index)" />
              </div>
            </div>
            <div
              v-if="currentSlide !== slides.length - 1"
              class="flex flex-row space-x-4"
            >
              <router-link
                to="/login"
                class="md:text-lg text-md pt-2 rounded-lg cursor-pointer border w-1/2 text-center border-custom-purple text-custom-purple"
              >
                Skip
              </router-link>
              <ButtonPrimary
                text="Next"
                class="w-1/2"
                @click="changeSlide(currentSlide + 1)"
              />
            </div>
            <div v-else class="flex flex-row space-x-4">
              <router-link
                to="/login"
                class="w-full text-white md:text-lg text-md px-2 py-2 rounded-lg bg-gradient-to-r from-purple-600 to-indigo-700 cursor-pointer text-center"
              >
                Login
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
