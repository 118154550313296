<script setup lang="ts"></script>

<template>
  <svg
    width="56"
    height="55"
    viewBox="0 0 56 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_106_2280)">
      <ellipse
        cx="28"
        cy="27.5"
        rx="28"
        ry="27.5"
        fill="url(#paint0_linear_106_2280)"
      />
      <ellipse cx="28" cy="27.5002" rx="24.8" ry="24.3571" fill="#262626" />
    </g>
    <g clip-path="url(#clip1_106_2280)">
      <path
        d="M28 22.375C28.3693 22.375 28.7351 22.3023 29.0763 22.1609C29.4175 22.0196 29.7276 21.8124 29.9887 21.5512C30.2499 21.2901 30.4571 20.98 30.5984 20.6388C30.7398 20.2976 30.8125 19.9318 30.8125 19.5625C30.8125 19.1932 30.7398 18.8274 30.5984 18.4862C30.4571 18.145 30.2499 17.8349 29.9887 17.5738C29.7276 17.3126 29.4175 17.1054 29.0763 16.9641C28.7351 16.8227 28.3693 16.75 28 16.75C27.2541 16.75 26.5387 17.0463 26.0113 17.5738C25.4838 18.1012 25.1875 18.8166 25.1875 19.5625C25.1875 20.3084 25.4838 21.0238 26.0113 21.5512C26.5387 22.0787 27.2541 22.375 28 22.375ZM35.5 28C36.2459 28 36.9613 27.7037 37.4887 27.1762C38.0162 26.6488 38.3125 25.9334 38.3125 25.1875C38.3125 24.4416 38.0162 23.7262 37.4887 23.1988C36.9613 22.6713 36.2459 22.375 35.5 22.375C34.7541 22.375 34.0387 22.6713 33.5113 23.1988C32.9838 23.7262 32.6875 24.4416 32.6875 25.1875C32.6875 25.9334 32.9838 26.6488 33.5113 27.1762C34.0387 27.7037 34.7541 28 35.5 28ZM23.3125 26.125C23.3125 26.8709 23.0162 27.5863 22.4887 28.1137C21.9613 28.6412 21.2459 28.9375 20.5 28.9375C19.7541 28.9375 19.0387 28.6412 18.5113 28.1137C17.9838 27.5863 17.6875 26.8709 17.6875 26.125C17.6875 25.3791 17.9838 24.6637 18.5113 24.1363C19.0387 23.6088 19.7541 23.3125 20.5 23.3125C21.2459 23.3125 21.9613 23.6088 22.4887 24.1363C23.0162 24.6637 23.3125 25.3791 23.3125 26.125ZM24.25 37.375C24.9959 37.375 25.7113 37.0787 26.2387 36.5512C26.7662 36.0238 27.0625 35.3084 27.0625 34.5625C27.0625 33.8166 26.7662 33.1012 26.2387 32.5738C25.7113 32.0463 24.9959 31.75 24.25 31.75C23.5041 31.75 22.7887 32.0463 22.2613 32.5738C21.7338 33.1012 21.4375 33.8166 21.4375 34.5625C21.4375 35.3084 21.7338 36.0238 22.2613 36.5512C22.7887 37.0787 23.5041 37.375 24.25 37.375Z"
        fill="white"
      />
      <path
        d="M43 28C43 33.9063 39.5013 32.8469 36.3119 31.8813C34.4125 31.3056 32.6219 30.7619 31.75 31.75C30.6194 33.0306 30.8594 35.1606 31.0919 37.225C31.4238 40.1781 31.7425 43 28 43C25.0333 43 22.1332 42.1203 19.6665 40.4721C17.1997 38.8238 15.2771 36.4811 14.1418 33.7403C13.0065 30.9994 12.7094 27.9834 13.2882 25.0737C13.867 22.1639 15.2956 19.4912 17.3934 17.3934C19.4912 15.2956 22.1639 13.867 25.0737 13.2882C27.9834 12.7094 30.9994 13.0065 33.7403 14.1418C36.4811 15.2771 38.8238 17.1997 40.4721 19.6665C42.1203 22.1332 43 25.0333 43 28ZM28 41.125C29.1456 41.125 29.2263 40.8044 29.2281 40.795C29.3744 40.5213 29.4606 39.925 29.3594 38.6969C29.3331 38.3819 29.29 38.0031 29.245 37.5888C29.1475 36.7188 29.035 35.7044 29.0238 34.8475C29.005 33.5219 29.1794 31.8288 30.3438 30.5088C31.0356 29.7269 31.9281 29.425 32.7288 29.35C33.4863 29.2788 34.2513 29.3988 34.9038 29.545C35.5469 29.6894 36.22 29.8938 36.8256 30.0775L36.8781 30.0925C37.5269 30.2894 38.1119 30.4656 38.665 30.5913C39.8894 30.8688 40.36 30.7469 40.5231 30.6363C40.5944 30.5875 41.125 30.1769 41.125 28C41.125 25.4041 40.3552 22.8665 38.913 20.7081C37.4709 18.5498 35.421 16.8675 33.0227 15.8741C30.6244 14.8807 27.9854 14.6208 25.4394 15.1272C22.8934 15.6336 20.5548 16.8837 18.7192 18.7192C16.8837 20.5548 15.6336 22.8934 15.1272 25.4394C14.6208 27.9854 14.8807 30.6244 15.8741 33.0227C16.8675 35.421 18.5498 37.4709 20.7081 38.913C22.8665 40.3552 25.4041 41.125 28 41.125Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_106_2280"
        x1="28"
        y1="0"
        x2="28"
        y2="55"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#60A5FA" />
        <stop offset="1" stop-color="#8B5DF1" />
      </linearGradient>
      <clipPath id="clip0_106_2280">
        <rect width="56" height="55" fill="white" />
      </clipPath>
      <clipPath id="clip1_106_2280">
        <rect
          width="30"
          height="30"
          fill="white"
          transform="translate(13 13)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
