<script setup lang="ts">
import { defineProps } from "vue/dist/vue";

const props = defineProps<{
  text: string;
}>();
</script>

<template>
  <label class="text-custom-middleGray text-left text-lg md:text-xl">
    {{ props.text }}
  </label>
</template>
