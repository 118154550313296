import { defineStore } from "pinia";
import { computed, ComputedRef, Ref, ref } from "vue";
import { FriendRequest } from "@/interfaces/FriendRequest";
import { useAuthStore } from "@/store/useAuthStore";
import friendsData from "../json/friends.json";
import { useUsersStore } from "@/store/useUsersStore";
import { FriendInfos } from "@/interfaces/FriendInfos";

export const useFriendsStore = defineStore("friends", () => {
  const friendRequests: Ref<FriendRequest[]> = ref(friendsData);

  function addFriendRequest(from: number, to: string): boolean {
    const usersStore = useUsersStore();
    const toFind = usersStore.getUserByName(to);
    if (toFind) {
      const toNumber = toFind.id;

      const friendRequestExist = friendRequests.value.some(
        (request) =>
          (request.from === from && request.to === toNumber) ||
          (request.from === toNumber && request.to === from)
      );

      if (!friendRequestExist) {
        const colorFrom = usersStore.getUserById(from)?.color;
        const colorTo = toFind.color;
        friendRequests.value.push({
          from,
          to: toNumber,
          confirmed: false,
          colorFrom: colorFrom ? colorFrom : "#ffffff",
          colorTo: colorTo ? colorTo : "#000000",
        });
        return true;
      }
    }
    return false;
  }

  const friendRequestsFromMe = computed(() => {
    const authStore = useAuthStore();
    return friendRequests.value.filter((request) => {
      return request.from === authStore.currentUser?.id && !request.confirmed;
    });
  });

  const friendRequestsToMe = computed(() => {
    const authStore = useAuthStore();
    return friendRequests.value.filter((request) => {
      return request.to === authStore.currentUser?.id && !request.confirmed;
    });
  });

  function deleteFriendRequest(from: number, to: number): void {
    const indexFriendRequest = friendRequests.value.findIndex(
      (request) => request.from === from && request.to === to
    );
    friendRequests.value.splice(indexFriendRequest, 1);
  }

  function indexFriendRequest(from: number, to: number): void {
    const indexFriendRequest = friendRequests.value.findIndex(
      (request) => request.from === from && request.to === to
    );
    friendRequests.value[indexFriendRequest].confirmed = true;
  }

  const friendsInfos: ComputedRef<FriendInfos[]> = computed(() => {
    const authStore = useAuthStore();

    const confirmedFriendRequests = friendRequests.value.filter(
      (request) =>
        (request.from === authStore.currentUser?.id ||
          request.to === authStore.currentUser?.id) &&
        request.confirmed
    );

    return confirmedFriendRequests.map((request) => ({
      friendId:
        request.from === authStore.currentUser?.id ? request.to : request.from,
      friendRequestInfos: request,
    }));
  });

  function updateColor(
    newColor: string,
    isFromUser: boolean,
    from_id: number,
    to_id: number
  ) {
    const indexFriendRequest = friendRequests.value.findIndex(
      (request) => request.from === from_id && request.to === to_id
    );
    if (isFromUser) {
      friendRequests.value[indexFriendRequest].colorFrom = newColor;
    } else {
      friendRequests.value[indexFriendRequest].colorTo = newColor;
    }
  }

  return {
    friendRequests,
    addFriendRequest,
    friendRequestsFromMe,
    friendRequestsToMe,
    deleteFriendRequest,
    indexFriendRequest,
    friendsInfos,
    updateColor,
  };
});
