import { defineStore } from "pinia";
import { User } from "@/interfaces/User";
import usersData from "../json/users.json";
import { Ref, ref } from "vue";

export const useUsersStore = defineStore("users", () => {
  const users: Ref<User[]> = ref(usersData);

  function addUser(user: User) {
    users.value.push(user);
    return users.value.length - 1;
  }

  function getUserById(id: number) {
    return users.value.find((user) => user.id === id);
  }

  function getUserByEmail(email: string) {
    return users.value.find((user) => user.email === email);
  }

  function getUserByName(name: string) {
    return users.value.find((user) => user.name === name);
  }

  function getUserByEmailAndPassword(email: string, password: string) {
    return users.value.find(
      (user) => user.email === email && user.password === password
    );
  }

  return {
    users,
    addUser,
    getUserById,
    getUserByName,
    getUserByEmail,
    getUserByEmailAndPassword,
  };
});
